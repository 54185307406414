<template>
    <div class="searchbar">
        <label for="search-input" class="sr-only">Search Minecraft Servers</label>
        <input
                id="search-input"
                v-model="search"
                placeholder="Search Minecraft Servers"
                type="text"
                @keyup="keyUp"
        >
        <button type="submit" @click.prevent="triggerSearch">
            <img alt="Search Button" src="~/assets/icons/right_arrow_circle.svg">
        </button>
    </div>
</template>

<script setup>
const search = ref("");

function keyUp(event) {
    if (event.key === "Enter") {
        triggerSearch();
    }
}

function triggerSearch() {
    // Remove focus state from button
    document.activeElement.blur();
    if (search.value) {
        const searchUrl = encodeURIComponent(search.value.trim()).replace(/ |%20/g, "+");
        useRouter().push(`/search/${searchUrl}`)
    }
}
</script>

<style lang="scss">
.searchbar {
  position: relative;
  width: 50%;
  margin: 2em auto 1em;

  input {
    width: 100%;
    padding: 0.5em 1em;
    font-size: 1.2rem;

    box-sizing: border-box;

    color: #555;

    border: 1px solid #e2e8f0;
    border-radius: 6px;

    &::placeholder {
      color: #a0aec0;
      text-align: left;
      font-weight: 400;
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 0.35em;
    transform: translateY(-50%);
    background: none;
    border: 0;
    cursor: pointer;

    img {
      width: 2em;
      height: 2em;
    }

    &:hover,
    &:focus {
      filter: invert(48%) sepia(54%) saturate(452%) hue-rotate(95deg) brightness(100%) contrast(92%);
      transform: translateY(-50%) scale(1.05);
    }
  }
}

@media (max-width: 768px) {
  .searchbar {
    width: 95%;
  }
}
</style>
