<template>
  <div class="paging">
    <div v-if="filter === 'user'" class="add-button">
      <a href="/server/add">Add Server</a>
    </div>
    <!-- back button -->
    <a :class="{ disabled: currentPage <= 1 }" class="back"
      :href="currentPath + (currentPage - 1 > 1 ? `/${currentPage - 1}` : '')">&lt
    </a>
    <!-- 1. page (with ...) -->
    <template v-if="currentPage - 2 >= 1">
      <a v-if="currentPage - 2 >= 1" :href="`${currentPath}`">1</a>
      <div class="many">...</div>
    </template>
    <!-- previous -->
    <a v-if="currentPage - 1 >= 1" :href="currentPath + (currentPage - 1 > 1 ? `/${currentPage - 1}` : '')">
      {{ currentPage - 1 }}
    </a>
    <div class="active">
      {{ currentPage }}
    </div>
    <!-- next -->
    <a v-if="currentPage + 1 <= maxPage" :href="`${currentPath}/${currentPage + 1}`">
      {{ currentPage + 1 }}
    </a>
    <!-- last page (with ...) -->
    <template v-if="currentPage + 2 <= maxPage">
      <div class="many">...</div>
      <a v-if="currentPage + 2 <= maxPage" :href="`${currentPath}/${maxPage}`">
        {{ maxPage }}
      </a>
    </template>
    <!-- next button -->
    <a :class="{ disabled: currentPage >= maxPage }" class="next"
      :href="currentPage < maxPage ? `${currentPath}/${currentPage + 1}` : ''">
      &gt;
    </a>
  </div>
</template>

<script setup>
const props = defineProps({
  maxPage: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  filter: {
    type: String,
  },
});
const currentPath = ref(getCurrentPath());

function getCurrentPath() {
  const { fullPath, params } = useRoute();
  let currentPath = fullPath;

  if (currentPath.includes("/login") || currentPath === "/") {
    currentPath = "/rank";
  }

  if (params.page) {
    currentPath = currentPath.replace(`/${params.page}`, "");
  } else if (currentPath.endsWith("/1")) {
    currentPath = currentPath.replace("/1", "");
  }

  if (currentPath.endsWith("/")) {
    currentPath = currentPath.slice(0, -1);
  }

  return currentPath;
}

</script>

<style scoped lang="scss">
.paging {
  display: flex;
  gap: 0.5em;
  place-self: flex-end;

  &>*:not(.add-button) {
    width: 2.5em;
    height: 2.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid #bebebe;
    border-radius: 4px;

    font-weight: bold;

    cursor: pointer;

    @media (max-width: 425px) {
      width: 2.25em;
      height: 2.25em;
      font-size: 0.95rem;
    }

    &:hover,
    &:focus {
      border: 2px solid #25855a;
      background-color: #c6f6d5;
      color: #38a169;
    }

    &.disabled {
      border: 2px solid #ececec;
      color: #b3b3b3;
      pointer-events: none;
    }

    &.active {
      border: 2px solid #25855a;
      background-color: #c6f6d5;
      color: #38a169;
    }

    &.many {
      border: none;
      padding-top: 0.25em;
      pointer-events: none;
    }
  }
}

a {
  text-decoration: none;
  color: #000;
}
</style>

<style lang="scss">
.add-button {
  all: unset;
  display: flex;
  justify-content: flex-end;

  a {
    width: fit-content;
    padding: 0.5em 1em;
    font-size: 1.1rem;
    background: #38a169;
    color: white;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    align-content: center;

    &:hover,
    &:focus {
      border: none;
      background: #2f855a;
    }
  }
}
</style>
