<template>
	<p v-if="!servers">Loading servers...</p>
	<div v-else class="servers">
		<div class="list-title-container">
			<h1 v-if="typeof pageTitle === 'string'" class="list-title-text">
				{{ pageTitle }}
			</h1>
			<div v-else-if="typeof pageTitle === 'object'" class="list-title-text ">
				<h1 class="list-title-text list-title-text-big">
					{{ pageTitle[0] }}
				</h1>
				<h2 class="list-title-text" v-html="pageTitle[1]"/>
				<h3 v-if="pageTitle[2]" class="list-title-text" v-html="pageTitle[2]"/>
			</div>
			<IndexPaging class="paging-desktop" :current-page="currentPage" :max-page="maxPages" :filter="filter"/>
		</div>
		<div class="server-tiles-header">
			<div class="rank">
				<img alt="Rank" src="~/assets/icons/like.svg" width="24" height="24">
				<span>Rank</span>
			</div>
			<div class="info">
				<img alt="Server and MOTD" src="~/assets/icons/info.svg" width="24" height="24">
				<span>Server and MOTD</span>
			</div>
			<div class="online">
				<img alt="Players & Votes" src="~/assets/icons/arrow_up.svg" width="26" height="25">
				<span>Players online</span>
			</div>
			<div class="ip">
				<img alt="IP Address" src="~/assets/icons/pin.svg" width="25" height="24">
				<span>IP Address</span>
			</div>
		</div>
		<template v-if="servers && servers.length > 0">
			<div class="server-tiles-container">
				<TilesServerListTile v-for="(server, index) in servers" :key="server.id" :server="server" :index="index"
				                     :current-page="currentPage" :featured-servers="featuredServersLength"
				                     :sponsorInfo="sponsorInfo"/>
			</div>
			<IndexPaging class="paging-desktop" :current-page="currentPage" :max-page="maxPages" :filter="filter"/>
		</template>
		<p v-else v-html="noServersMessage"/>
	</div>
</template>

<script setup>
import { sanitizeBanner, titleEncode } from "~/utils/helpers.js";

const gonePage = ref(false);

const route = useRoute();

const params = route.query
if (params && (params.action || params.listingID)) {
	setResponseStatus(410);
	gonePage.value = true;

	watch(() => route.fullPath, () => {
		if (gonePage.value && route.fullPath === "/") {
			gonePage.value = false;
		}
	});
}

const props = defineProps({
	filter: {
		type: String,
		default: "default",
	},
	tag: {
		type: Object,
		default: null,
	},
	search: {
		type: String,
		default: null,
	},
});

let emptyPage = false;

const paramsPage = route.params.page
const currentPage = paramsPage ? Number(paramsPage) : 1

let query = {};

let noServersMessage = 'No servers found.';

if (props.filter === "default") {
	query = {
		filters: {
			type: "top"
		}
	};
} else if (props.filter === "tag") {
	if (props.tag === null) {
		emptyPage = true
	} else {
		const filterTag = props.tag;
		delete filterTag.similar;
		query = {
			filters: {
				type: "tag",
				tag: {
					_id: filterTag._id,
					name: filterTag.name,
				}
			},
		};
	}

	noServersMessage = `No servers found with the tag <b>${props.tag.name}</b>.`;
} else if (props.filter === "user") {
	query = {
		filters: {
			type: "user"
		},
		sorts: {
			creation_date: -1,
		},
	};

	noServersMessage = `You have not added any servers yet.`;
} else if (props.filter === "favourite") {
	query = {
		filters: {
			type: "favourites"
		},
	};

	noServersMessage = `You have not favorited any servers yet.`;
} else if (props.filter === "search") {
	query = {
		filters: {
			type: "search",
			query: props.search
		},
	};

	noServersMessage = `No servers found with the search term <b>${props.search.replaceAll("+", " ")}</b>.`;
}

const servers_on_page = 16;

query.limit = servers_on_page;
query.offset = (currentPage - 1) * servers_on_page;

let servers = [];

const { data } = await useFetch("/api/server/many", {
	method: "POST",
	body: query,
	transform: (data) => {
		for (const server of data.servers) {
			server.poster = "/no_banner.webp"
			if (server.banner) {
				server.banner = sanitizeBanner(server.banner);
			}
			if (!server.description) {
				server.description = ""
			} else {
				server.description = server.description.substring(0, 250).replace(/<\/?[^>]+>/ig, " ");
			}
			server.href = "/server/" + titleEncode(server.title, server.custom_title_url) + "-" +
				(server.id || server._id)
		}
		return data;
	},
});

servers = data.value.servers;
if (servers.length === 0) emptyPage = true;

const total_servers = emptyPage ? 0 : data.value.total_servers;
const featuredServersLength = emptyPage ? 0 : data.value.featured_servers_length;

if (total_servers === 0 || total_servers - ((currentPage - 1) * servers_on_page) <= 0) {
	setResponseStatus(404);
}

const maxPages = Math.ceil(total_servers / servers_on_page);

const getPageTitle = () => {
	if (props.filter === "default") {
		return [
			"Minecraft Server List",
			"Minecraft Servers",
			"Find the best Minecraft multiplayer servers here."
		];
	} else if (props.filter === "tag") {
		return [
			`Minecraft ${data.value.tagName} Servers`,
			`Minecraft servers tagged as <b>${data.value.tagName}</b> ` +
			(servers.length === 0 ? "" : `(${(currentPage - 1) * servers_on_page + 1}-${
				((currentPage - 1) * servers_on_page) + servers.length - featuredServersLength})`),
		];
	} else if (props.filter === "user") {
		return "Minecraft Servers by User"
	} else if (props.filter === "favourite") {
		return "Favourite Minecraft Servers"
	} else if (props.filter === "search") {
		return "Minecraft Servers by " + props.search.replaceAll("+", " ");
	}
};

const pageTitle = getPageTitle();

const getSponsorInfo = () => {
	if (props.filter === "tag") {
		return "This is a sponsored server. It may not offer the " + data.value.tagName + " gamemode, but it's still worth exploring!"
	} else if (props.filter === "search") {
		return "This is a sponsored server. It may not offer the " + props.search + " gamemode, but it's still worth exploring!"
	} else {
		return null
	}
};

const sponsorInfo = getSponsorInfo();

const onLoad = async () => {

	const isAdminCookie = useCookie("is_admin");

	if (!isAdminCookie.value) return;
	for (const server of servers) {
		server.ipCopyData = await $fetch(
			"/api/ipcopy/" +
			server.ip +
			(server.port && server.port != 25565 ? ":" + server.port : ""),
		);
	}
};

onMounted(onLoad);
// watch(route, onLoad);

useJsonld({
	"@context": "http://schema.org",
	"@type": "WebPage",
	"video": [
		...servers.filter((server) => {
			return server.banner && (server.banner.endsWith('mp4') || server.banner.endsWith('webm'))
		}).map((server) => {
			return {
				"@type": "VideoObject",
				"name": server.title,
				"contentUrl": server.banner,
				"uploadDate": server.creation_date ?? "2023-11-03T00:00:00.000+00:00",
				"thumbnailUrl": [
					server.poster
				],
			}
		})
	],
	"image": [
		...servers.filter((server) => {
			return server.banner &&
				(server.banner.endsWith("webp") || server.banner.endsWith('png') || server.banner.endsWith('jpg') ||
					server.banner.endsWith('jpeg') || server.banner.endsWith('gif') || server.banner.endsWith('svg'))
		}).map((server) => {
			return {
				"@type": "ImageObject",
				"name": server.title,
				"contentUrl": server.banner,
			}
		})
	]
})
</script>

<style lang="scss">
.servers {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  @media (max-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.list-title-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;

  // For mobile, flex column
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
    }
  }

  h2 {
    display: flex;
    flex-direction: row;
    gap: 5px;
    @media (max-width: 768px) {
      gap: 5px;
      font-size: 1.1em;
    }
  }
}

.server-tiles-header {
  display: flex;
  background-color: #E2E8F0;
  border-radius: 10px 10px 0 0;
  padding: 25px;
  gap: 20px;
  margin-top: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1em;
  }

  .rank {
    flex: 1;
  }

  .info {
    flex: 6;
  }

  .online {
    flex: 2;
  }

  .ip {
    flex: 3;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.server-tiles-container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    padding: 5px;
    gap: 5px;
  }
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  min-height: 1.3em;
  font-size: 1.8em;
}

h2,
h3 {
  min-height: 1.4em;
  font-weight: 400;
  font-size: 1.3em;
  @media (max-width: 768px) {
    font-size: 1.1em;
    text-align: center;
    align-self: center;
  }
}

.list-title-text {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .paging {
    place-self: center !important;
  }
}

;</style>