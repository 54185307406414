<template>
	<div class="server-list-tile" :class="{ 'sponsor-tile': server.featured && index < featuredServers }">
		<div class="server-tile-ranking">
			<a :href="server.href" :aria-label="'View more information about ' + server.title">
				<div class="server-index">
					<div v-if="server.featured && index < featuredServers" class="featured">
						<img class="sponsor" alt="Star icon for sponsored servers" src="~/assets/icons/star.svg">
						<p>Sponsor</p>
					</div>
					<div v-else class="numbered">
						<p>{{ actualIndex }}</p>
					</div>
				</div>
				<div class="sponsor-info" @click="(e) => e.preventDefault()" v-tippy="sponsorInfo"
				     v-if="server.featured && index < featuredServers && sponsorInfo">
					<img alt="Sponsor Info" src="~/assets/icons/info-thin.svg" width="32" height="32">
				</div>
			</a>
		</div>
		<div class="server-tile-info">
			<a :href="server.href">
				<h3 class="title">{{ server.title }}</h3>
			</a>
			<a class="banner-video" :href="server.href">
				<IndexBannerComponent :key="`banner_big_${index}`" :server="server"/>
			</a>
			<p class="description">
				<a :href="server.href">
					{{ server.description }}
				</a>
			</p>
		</div>
		<div class="information">
			<div class="server-tile-online">
				<div class="status" :class="!(server?.status?.uptime ?? false) ? 'offline' : ''">
					<p>{{ (server?.status?.uptime ?? false) ? "ONLINE" : "OFFLINE" }}</p>
					<div
							v-if="server?.status?.players" class="count"
							:class="!(server?.status?.uptime ?? false) ? 'offline' : ''">
						<span>PLAYERS:</span>
						<span class="small">{{ server.status.players.online ?? 0 }}/{{
								server.status.players.max
							}}</span>
					</div>
					<div class="edition">
            <span class="small">{{
		            (server.bedrock_ip && server.bedrock_status && server.bedrock_status.uptime) ?
				            "JAVA AND BEDROCK" :
				            "JAVA"
	            }}</span>
					</div>

				</div>
			</div>
			<div class="server-tile-ip" :class="chipClass">
				<div v-tippy="'Copy to clipboard'" class="ip-chip" :class="chipClass">
					<button :style="`--font-size-multiply: ${ipViewWidth};`" @click.prevent="copyIP">
						{{ serverIp }}
						<img alt="Copy IP" src="/assets/icons/copy_white.svg">
					</button>
				</div>
				<div v-if="server.ipCopyData" class="ipCopyData">
					<p>Loggined: {{ server.ipCopyData.users }}</p>
					<p>Anonymous: {{ server.ipCopyData.anonymous }}</p>
					<p>Total: {{ server.ipCopyData.total }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
    server: {
        type: Object,
        required: true
    },
    index: {
        type: Number,
        required: true
    },
    currentPage: {
        type: Number,
        required: true
    },
    featuredServers: {
        type: Number,
        required: true
    },
    sponsorInfo: {
        type: String,
        required: true
    }
});

const chipClass = !(props.server?.status?.uptime ?? false) ? 'offline' : '';

const actualIndex = (props.currentPage - 1) * 16 + props.index - props.featuredServers + 1

// Calculates the size of the index number, so it's nicely fitting
const preferedIndexSize = 48 - (actualIndex.toString().length * 4) + "px"

const serverIp = props.server.ip + (props.server.port != 25565 ? ':' + props.server.port : '')

const ipViewWidth = serverIp.length < 15 ? 1 : (serverIp.length < 20 ? 0.95 : (serverIp.length < 25 ? 0.9 : (serverIp.length < 30 ? 0.85 : 0.8)))
</script>

<style lang="scss" scoped>
.server-list-tile {
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: row;
  background-color: white;
  padding: 20px 20px;
  border-bottom: 2px solid #e4e8ee;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 5px 5px;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.sponsor-tile {
  background: linear-gradient(180deg, rgba(255, 213, 10, 0.2) 0%, rgba(255, 255, 255, 1) 50%);

  .sponsor {
    filter: invert(81%) sepia(58%) saturate(910%) hue-rotate(350deg) brightness(142%) contrast(101%);
  }
}

.server-tile-ranking {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
  }
}

.server-index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E2E8F0;
  width: 6em;
  height: 6em;
  border-radius: 10px;
  @media (max-width: 768px) {
    display: none;
  }
}

.sponsor-info {
  text-align: center;
  margin-top: 1em;

  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    top: -0.5em;
    font-size: 1.5rem;
    margin-top: 0.75em;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.numbered p { // Use preferedIndexSize to make sure the number is always centered
  font-size: v-bind('preferedIndexSize');
  text-overflow: ellipsis;
  font-weight: 400;
  color: #475263;
  @media (max-width: 768px) {
    font-size: 1em;
  }
}

.information {
  display: flex;
  flex-direction: row;
  flex: 5;

  // Tablet
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    flex: 3;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 30px;
  }
}

.server-tile-info {
  display: flex;
  flex-direction: column;
  min-height: 8em;
  flex: 6;
  gap: 5px;

  a {
    text-decoration: none;
    color: inherit;
  }

  .banner-video {
    height: auto;
    width: 100%;
  }

  .description {
    min-height: 4em;
    font-weight: 400;

    line-height: 1.25rem;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow-wrap: anywhere;

    // Make the description non-clickable on desktop
    cursor: text;
    a {
      color: inherit;
      font-weight: inherit;
      pointer-events: none;
    }

    @media (max-width: 1024px) {
      font-size: 0.8rem;
      line-height: 1.2rem;
      min-height: 3em;
      width: 100%;
      -webkit-line-clamp: 2;

      // Make the description clickable on mobile
      cursor: pointer;
      a {
        pointer-events: auto;
      }
    }
  }
}

.featured {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sponsor {
    width: 3em;
    height: 3em;
    @media (max-width: 768px) {
      width: 2em;
      height: 2em;
    }
  }

  p {
    color: #475263;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.server-tile-online {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 30px;
    flex-direction: row;
  }

  .offline p {
    color: #982525;
  }

  p {
    color: #22543d;
  }

  .edition {
    font-size: 0.8em;
    background-color: #E2E8F0;
    color: #475263;
    width: fit-content;

    border-radius: 4px;
    padding: 8px;
  }

  .count {
    font-size: 0.9rem;
    font-family: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: 5px;
    border-radius: 4px;
    padding: 8px;
    background-color: #c6f6d5;
    color: #22543d;

    &.offline {
      background-color: #feb2b2;
      color: #982525;
    }

    .small {
      font-size: 0.8rem;
    }

    @media (max-width: 768px) {
      font-size: 0.7rem;
      .small {
        font-size: 0.7rem;
      }
    }
  }
}

.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: row;
  }

  * {
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.server-tile-ip {
  display: flex;
  flex: 3;
  align-items: center;

  flex-direction: column;
  align-self: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

p {
  margin: 0;
  font-size: 14px;
}

.title {
  font-size: 1.0rem;
  font-weight: 500;
  margin: 0;
  min-height: 1.5em;
}

.ip-chip {
  padding: 0.2em 0.4em;
  border-radius: 6px;
  white-space: nowrap;

  background: #2E8458;

  margin: 0 .75em;

  display: flex;
  align-items: center;

  &.offline {
    background: #c53030;
  }

  button {
    img {
      margin-left: 0.5em;
    }

    background: none;
    border: none;
    color: white;

    display: flex;
    align-items: center;

    cursor: pointer;
    font-size: calc(1.05rem * var(--font-size-multiply));

    @media (max-width: 1024px) {
      font-size: calc(0.95rem * var(--font-size-multiply));
    }

    img {
      width: 1.4em;
      height: 1.4em;
    }
  }
}
</style>